export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  FixFormUoUCheckboxLink: 'specs.bookings.FixFormUoUCheckboxLink',
  DayfulOverrideEcomContinueShoppingUrl:
    'specs.bookings.DayfulOverrideEcomContinueShoppingUrl',
  ImproveFormPerformance: 'specs.bookings.ImproveFormPerformance',
  Cart: 'specs.bookings.cart',
  FixWidgetsNavigationAfterCheckoutWasRemoved:
    'specs.bookings.FixWidgetsNavigationAfterCheckoutWasRemoved',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  CartBookNowButton: 'specs.bookings.CartBookNowButton',
  AddNewBiEvents: 'specs.bookings.AddNewBiEvents',
  FormPageURLAPI: 'specs.bookings.formPageURLAPI',
  HideCartBookNowButtonWhenCartIsNotEmpty:
    'specs.bookings.HideCartBookNowButtonWhenCartIsNotEmpty',
  CartBookNowSettings: 'specs.bookings.CartBookNowSettings',
  V1Shutdown: 'specs.bookings.V1Shutdown',
  CancellationPolicyChangeAPI: 'specs.bookings.CancellationPolicyChangeAPI',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  MergeFirstNameAndLastNameWhenLastNameFieldDontExist:
    'specs.bookings.MergeFirstNameAndLastNameWhenLastNameFieldDontExist',
  SaveSubmissionWithDataCapsule: 'specs.bookings.SaveSubmissionWithDataCapsule',
  HidePriceWhenBuyinAPlanInDynamicPriceDropdown:
    'specs.bookings.HidePriceWhenBuyinAPlanInDynamicPriceDropdown',
  ViewCartButtonWiredToSecondary:
    'specs.bookings.ViewCartButtonWiredToSecondary',
  AppSectionParamsAdapter: 'specs.bookings.AppSectionParamsAdapter',
  DynamicFormSchema: 'specs.bookings.DynamicFormSchema',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  AwaitForPPNavigation: 'specs.bookings.AwaitForPPNavigation',
  AddTimeZoneToContactDetails: 'specs.bookings.AddTimeZoneToContactDetails',
  PreventConflictsOnCart: 'specs.bookings.PreventConflictsOnCart',
  DisableFormValidationWhenPurchasingAPricingPlan:
    'specs.bookings.DisableFormValidationWhenPurchasingAPricingPlan',
};
